<template>
	<div class='page' style="height:75vh; position:relative; top:0" v-loading="loading" element-loading-text="正在打印"
		element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.5)"
		customClass="myloading">
		<div class="container" style="height:800px">


			<div class="box">
				<div class="item" style='text-align: center;'>
					<div>
						<img :src="domain+code" alt="">
						<p>微信扫码上传</p>
					</div>
					
					<span style='line-height: 40px; color:red'>小程序上传打印,请不要离开此页面</span>
				</div>
				
				


				<!-- 		<div class="item">
					<el-upload ref="upload" :auto-upload="true" drag :http-request="uploadFile" action="customize"
						:accept="accept" :file-list="fileList">
						<img :src="upan" alt="">
					</el-upload>
					<p>U盘选择</p>
					<div style="text-align: center; color:#fff" v-if="type == 'pic'">支持.jpg, .jpeg, .png格式</div>
					<div style="text-align: center; color:#fff" v-else>支持.doc, .docx, .xls, .xlsx, .pdf格式</div>
				</div> -->

			</div>



			<el-dialog :visible.sync="spec" width="40%" :before-close="handleClose">
				<div>请选择规格</div>


				<el-form :model="wxData" ref="wxData" label-width="100px" class="demo-wxData">
					<el-form-item label="打印数量">
						<el-input-number v-model="wxData.print_number" :min="1" @change="sumMoney()" label="描述文字">
						</el-input-number>
					</el-form-item>

					<el-form-item label="打印类型" v-if="type=='file' || type=='pic' ">
						<el-radio-group v-model="wxData.paper_type" @change="sumMoney()">
							<el-radio label="1" border :disabled="dis_hb">黑白</el-radio>
							<el-radio label="2" border>彩色</el-radio>
						</el-radio-group>
					</el-form-item>


					<el-form-item label="打印规格" v-if="type=='file'">
						<el-radio-group v-model="wxData.print_type" @change="sumMoney()">
							<el-radio label="1" border v-if="model == 2">A3</el-radio>
							<el-radio label="2" border>A4</el-radio>
						</el-radio-group>
					</el-form-item>

					<el-form-item label="打印类型类型" v-if="type=='file'">
						<el-radio-group v-model="wxData.print_ctype" @change="sumMoney()">
							<el-radio label="1" border>单面</el-radio>
							<el-radio label="2" border>双面</el-radio>
						</el-radio-group>
					</el-form-item>


					<el-form-item label="相片尺寸" v-if="type=='zjz'">
						<el-radio-group v-model="wxData.print_type" @change="sumMoney()">
							<el-radio label="3" border>一寸</el-radio>
							<el-radio label="4" border>两寸</el-radio>
							<el-radio label="5" border>大一寸</el-radio>
							<el-radio label="6" border>大两寸</el-radio>

						</el-radio-group>
					</el-form-item>


					<el-form-item label="相片尺寸" v-if="type=='pic'">
						<el-radio-group v-model="wxData.print_type" @change="sumMoney()">
							<el-radio label="2" border>文档(a4纸)</el-radio>
						</el-radio-group>
						&nbsp;
						<el-radio-group v-model="wxData.print_type" @change="sumMoney()">
							<el-radio label="7" border>照片(6寸)</el-radio>
						</el-radio-group>
					</el-form-item>


					<el-form-item label="是否群打印" v-if="is_enterprise == 1">
						<el-switch v-model="isGroup"></el-switch>
					</el-form-item>

					<el-form-item label="群打印成员" v-if="isGroup">
						<el-checkbox-group v-model="ids">
							<el-checkbox :label="item.id" name="type" v-for="(item,index) in accList">
								{{ item.real_name }}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>


					<el-form-item label="价格">
						<p>¥ {{ price }}</p>
					</el-form-item>


					<el-form-item>
						<el-button type="success" @click="pay('wx')">微信支付</el-button>
						<el-button type="success" @click="pay('ye',1)" v-if="$store.state.userinfo">余额支付</el-button>
						<el-button type="success" @click="pay('ye',2)" v-if="is_enterprise == 1">
							企业支付</el-button>
					</el-form-item>

				</el-form>

			</el-dialog>



			<el-dialog title="微信扫码支付" :visible.sync="showc" width="20%" :before-close="wxclose"
				style="text-align: center;">

				<vue-qr :text="wxcode" :size="200" style="margin:0 auto;width:200px; height:200px"></vue-qr>

			</el-dialog>







			<div ref="box1" class="photobox" style="">
				<div class="photo" style="width:20.4cm; height:30.4cm; display: block;">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
					<img class='pic1' :src="pic" alt=""
						style="float:left;width:5cm; height:7cm; border:0.05cm solid transparent">
				</div>
			</div>


			<div ref="box2" class="photobox" style="">
				<div class="photo" style="width:20.4cm; height:30.4cm;   text-align: center;   ">
					<div class="box" style="width:18cm; margin:0 auto">

						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:9.8cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:9.8cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:9.8cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:9.8cm; margin:1cm">



					</div>
				</div>
			</div>



			<div ref="box3" class="photobox" style="">
				<div class="photo" style="width:20.4cm; height:30.4cm;   text-align: center;   ">
					<div class="box" style="margin:0 auto">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:6.6cm; height:9.6cm; margin:0.1cm">
					</div>
				</div>
			</div>



			<div ref="box4" class="photobox" style="">
				<div class="photo" style="width:20.4cm; height:30.4cm;  text-align: center;  ">
					<div class="box" style="width:18cm; margin:0 auto">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:10.6cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:10.6cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:10.6cm; margin:1cm">
						<img class='pic1' :src="pic" alt="" style="float:left;width:7cm; height:10.6cm; margin:1cm">
					</div>
				</div>
			</div>



			<div ref="box5" class="photobox">
				<div class="photo" style="width:20.4cm; height:30.4cm;   text-align: center;">
					<img class='pic1' :src="pic" alt="" style="width:100%; height:100%">
				</div>
			</div>




		</div>




		<el-dialog :visible.sync="printOk" width="30%">
			<div class="info" style="text-align: center;">
				<p class='iconfont icon-chenggong' style="font-size: 100px; color:#67C23A"></p>
				<p style="color:#67C23A; font-size: 50px; line-height: 100px;">打印成功</p>

				<div v-if="$store.state.userinfo">
					<p style="color:red">为确保您的信息安全，打印完成后记得退出</p>
					<p>
						<el-button type="danger" style="width:80%" @click="loginout()">退出</el-button>
					</p>
					<p>
						<el-button type="primary" style="width:80%; margin:30px 0" @click="$router.go(0)">继续打印
						</el-button>
					</p>
				</div>
			</div>
		</el-dialog>



	</div>
</template>

<script>
	import vueQr from 'vue-qr'
	import {
		getLodop
	} from '../../utils/LodopFuncs'
	export default {
		components: {
			vueQr
		},
		data() {
			return {
				dis_hb: false,
				is_enterprise: null, //企业账号
				ids: [],
				accList: [],
				isGroup: false,
				code: require("@/assets/images/code2.jpg"),
				wxcode: '',
				upan: require("@/assets/images/upan.png"),
				fileList: [],
				spec: false,
				showc: false,
				printOk: false,
				loading: false,
				pic: '',
				price: '',
				type: '',
				accept: '',
				domain: '',
				model: 0,
				pageCount: 0,
				wxData: {
					type: "2", //打印支付
					task_type: '', //文档打印
					paper_type: '2', //彩色
					print_type: '2', //a4
					print_number: '1', //数量
					print_count: '0', //页码
					print_file: '', //文件路径
					print_ctype: '1', //单面
					money_type: ''
				},
				sumMoneyData: {
					type: '',
					page: '',
					number: '',
					ctype: '',
					paper_type: ''
				}
			}
		},

		created() {
			this.domain = this.$domain;
			console.log('type', this.$route.params.type)
			this.model = localStorage.getItem('model')
			this.type = this.$route.params.type;
			if (this.type == 'file') {
				this.accept = ".doc, .docx, .xls, .xlsx, .pdf"
				this.wxData.task_type = "1"
				this.wxData.print_type = "2";
			} else {
				if (this.type == 'pic') {
					this.wxData.task_type = "2"
					this.wxData.print_type = "7";
				} else {
					this.wxData.task_type = "3"
					this.wxData.print_type = "3";
				}

				this.accept = ".jpg, .jpeg, .png"

			}

			console.log('vuex', this.$store.state.userinfo)
			if (this.$store.state.userinfo) {

				if (this.$store.state.userinfo.is_enterprise == 1) {
					this.is_enterprise = 1;
					this.AccountList();
				}

			}
		},
		mounted() {
			if (!localStorage.getItem(this.type+'_code')) {
				this.weChatCode();
			} else {
				this.code = localStorage.getItem(this.type+'_code')
			}

		},

		methods: {
			async AccountList() {
				let query = {
					limit: 1000,
					page: 1
				}
				let res = await this.$api.AccountList(query);
				this.accList = res.data.rows;
				console.log('acc', this.accList)
			},


			//获小程序二维码
			async weChatCode() {
				if (localStorage.getItem('device')) {
					let scene = localStorage.getItem('device') + '?' + localStorage.getItem('model') + '?' + this.type;



					console.log('abc', scene)
					this.model = localStorage.getItem('model');
					//return false
					let data = {
						type: '1',
						scene: scene,
						ctype: '1',
						//	page:'pages/index/document/printPic'
					}
					console.log('参数', data);

					let res = await this.$api.weChatCode(data)
					if (res.code == '200') {
						this.code = res.data
						localStorage.setItem(this.type+'_code', this.code)
					}
				} else {
					this.$message.error('请先绑定设备编号')

				}


			},


			//计算价格
			async sumMoney() {

				this.sumMoneyData.type = this.wxData.print_type;
				this.sumMoneyData.number = this.wxData.print_number;
				this.sumMoneyData.page = this.pageCount;
				this.sumMoneyData.ctype = this.wxData.print_ctype;
				this.sumMoneyData.paper_type = this.wxData.paper_type;



				if (this.sumMoneyData.type == 7) {
					this.sumMoneyData.type = 9; //照片6寸
					this.wxData.paper_type = '2';
					this.dis_hb = true;
				} else {
					this.dis_hb = false;
				}


				if (this.wxData.print_ctype == 2) { //双页打印
					this.sumMoneyData.page = Math.ceil(this.pageCount / 2);
					this.wxData.print_count = this.sumMoneyData.page;
				} else {
					this.wxData.print_count = this.pageCount
				}

				console.log('支付参数', this.sumMoneyData)

				let res = null;
				if (this.$store.state.userinfo) {
					res = await this.$api.sumMoney(this.sumMoneyData);
				} else {
					res = await this.$api.yksumMoney(this.sumMoneyData);
				}



				this.price = res.data
				this.wxData.money_type = res.name;

				console.log('cc', res)

				/* 	} catch {
						this.$message.error('数据请求失败');
					} */
			},


			async saveTask(type) {
				let data = {
					'type': this.wxData.task_type,
					'is_mass': 2,
					'paper_type': this.wxData.paper_type,
					'print_file': this.wxData.print_file,
					'print_hb_file': this.wxData.print_hb_file,
					'print_count': this.wxData.print_count,
					'print_number': this.wxData.print_number,
					'user_data': this.ids,
					'money_type': this.wxData.money_type,
					'print_ctype': this.wxData.print_ctype,
					'print_type': this.wxData.print_type
				}
				console.log('任务参数', data);

				let res = await this.$api.saveTask(data);

				if (res.code == 200) {
					console.log('任务', res)
					let param = {
						'id': res.id,
						'type': type + 1
					}
					this.orderPay(param, res.order_number)
				} else {
					this.$message.error(res.msg)
				}



			},



			//订单支付
			async orderPay(data, order_number) {
				let res = await this.$api.orderPay(data);
				console.log('订单', res)
				if (res.code == 200) {
					this.$message.success(res.msg)

					let param = {
						'uid': this.$store.state.userinfo.id,
						'number': order_number,
					}
					this.OrderDetail(param)

				} else {
					this.$message.error(res.msg)
				}
			},


			async pay(type, enter = 0) {
				this.loading = true;
				this.spec = false;
				console.log(333)


				if (type == 'wx') {
					this.wxData.type = 2; //打印支付

					let res = null;
					if (this.$store.state.userinfo) {
						res = await this.$api.scanPay(this.wxData); //会员扫码支付
					} else {
						res = await this.$api.ykscanPay(this.wxData); //游客扫码支付
						console.log('游客扫码参数', res)
					}

					console.log('微信数据', this.wxData);


					this.wxcode = res.data.code_url;
					console.log('code', this.code)
					this.showc = true;


					this.timer = setInterval(() => {
						if (this.$store.state.userinfo) {
							this.scanPaySuccessful(res.data.order_number)

						} else {
							this.ykscanPaySuccessful(res.data.order_number);
						}

					}, 1000)

				} else {

					this.wxData.ctype = 2; //打印支付
					this.wxData.type = enter; //企业 或个人

					//群打印需要先创建任务
					if (this.isGroup) {
						this.saveTask(enter);
						return false;
					}

					console.log('余额支付')
					let res = await this.$api.balancePayment(this.wxData);

					let data = this.wxData;
					data.task_number = res.task_number
					//文档打印
					if (this.wxData.task_type == 1) {
						console.log('微信参数', this.wxData);

						this.printFile(data)

					} else { //图片打印
						this.print(data)
					}


				}

			},



			async OrderDetail(data) {

				try {
					let res = await this.$api.OrderDetail(data);
					if (res.code == 200) {
						console.log('订单详情11', res);

						//	res.data.print_file = this.$domain + res.data.print_file


						let hb = res.data.print_file.split('.');
						hb = hb[0] + '_hb.' + hb[1];
						res.data.print_hb_file = hb;
						console.log('hb', hb)

						if (res.data.order_status == '1') {
							if (res.data.task_type == '2' || res.data.task_type == '3' || res.data.task_type ==
								'5') { //照片打印

								this.pic = this.$domain + data.print_file;
								console.log('照片准备打印1', this.pic)
								this.print(res.data);
							}

							if (res.data.task_type == '1') { //文档打印
								this.pic = res.data.print_file;

								console.log('文档准备打印')
								this.printFile(res.data);
							}

						}

					}


				} catch (err) {
					this.$message.error('数据请求失败');
				}
			},




			handleClose() {
				this.spec = false
			},

			wxclose() {
				clearTimeout(this.timer);
				this.showc = false;
			},

			//支付状态
			async scanPaySuccessful(order) {
				let res = await this.$api.scanPaySuccessful({
					'order_number': order
				});
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);
					this.showc = false;

					if (this.wxData.task_type == '2' || this.wxData.task_type == '3' || this.wxData.task_type ==
						'5') { //照片打印

						//this.pic = this.$domain + this.wxData.print_file;
						console.log('微信参数', this.wxData)
						console.log('照片准备打印', this.pic)

						this.print(this.wxData);
					}

					if (this.wxData.task_type == '1') { //文档打印
						this.pic = this.wxData.print_file;

						console.log('文档准备打印')
						this.printFile(this.wxData);
					}

				}
			},

			async ykscanPaySuccessful(order) {
				let res = await this.$api.ykscanPaySuccessful({
					'order_number': order
				});
				console.log(res);
				if (res.data == "SUCCESS") {
					clearTimeout(this.timer);



					if (this.wxData.task_type == '2' || this.wxData.task_type == '3' || this.wxData.task_type ==
						'5') { //照片打印

						//	this.pic = this.wxData.print_file;
						console.log('照片准备打印', this.pic)
						this.print(this.wxData);
					}

					if (this.wxData.task_type == '1') { //文档打印
						this.pic = this.wxData.print_file;

						console.log('文档准备打印')
						this.printFile(this.wxData);
					}


					this.showc = false;

				}
			},


			handleChange(file, fileList) {
				console.log('改变了', file)
			},


			// 文件上传
			uploadFile(params) {
				console.log("上传参数", params);



				const _file = params.file;


				// 通过 FormData 对象上传文件
				var formData = new FormData();
				formData.append("file", _file);

				const loading = this.$loading({
					lock: true,
					text: '文件上传中',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});


				if (this.$store.state.userinfo) {
					formData.append("type", 2);
					if (this.type == 'file') {
						formData.append("print_type", 1); //文件
					} else if (this.type == 'pic') {
						formData.append("print_type", 2);
					} else {
						formData.append("print_type", 3);
					}

					// 发起请求
					this.$api.memUpload(formData).then(res => {
						console.log('会员上传', res)
						if (res.code == 200) {

							this.wxData.print_file = res.data.file_url;
							let hb = res.data.file_url.split('.');
							hb = hb[0] + '_hb.' + hb[1];
							console.log('hb', )
							console.log('hb', hb)

							this.wxData.print_hb_file = hb;


							this.pic = this.$domain + this.wxData.print_file;
							console.log('用户图片', this.pic)
							this.pageCount = res.data.page;


							if (this.type == 'file') {
								this.$api.blackPDF({
									'inputPath': res.data.file_url
								}).then(res2 => {
									console.log('pdf黑白处理的问题', res2)
									this.spec = true;
									loading.close();

									this.sumMoney();
								})
							}

							if (this.type == 'pic') {
								this.$api.blackImg({
									'inputPath': res.data.file_url
								}).then(res2 => {
									console.log('pic黑白处理的问题', res2)
									this.spec = true;
									loading.close();

									this.sumMoney();
								})
							}




						} else {
							this.$message.error('文档上传失败');
						}


					})
				} else {
					console.log('游客数据', formData)
					this.$api.uploadFile(formData).then(res => {
						console.log('游客上传', res)
						if (res.code == 200) {

							this.wxData.print_file = res.data.url;


							let hb = res.data.url.split('.');
							hb = hb[0] + '_hb.' + hb[1];
							console.log('hb', )
							console.log('hb', hb)

							this.wxData.print_hb_file = hb;

							this.wxData.pageCount = res.data.page;
							this.pic = this.$domain + this.wxData.print_file

							console.log('游客图片', this.pic)

							this.pageCount = res.data.page;


							if (this.type == 'file') {
								this.$api.blackPDF({
									'inputPath': res.data.file_url
								}).then(res2 => {
									console.log('pdf黑白处理的问题', res2)
									this.spec = true;
									loading.close();

									this.sumMoney();
								})
							}

							if (this.type == 'pic') {
								this.$api.blackImg({
									'inputPath': res.data.file_url
								}).then(res2 => {
									console.log('pic黑白处理的问题', res2)
									this.spec = true;
									loading.close();

									this.sumMoney();
								})
							}


						} else {
							this.$message.error('文档上传失败');
						}


					})
				}



			},




			open() {
				this.printOk = true;

				if (this.$store.state.userinfo) {
					//this.$router.go(0)
				} else {
					setTimeout(() => {
						this.$router.push('/RegisterVip')
					}, 3000)

				}

			},


			loginout() {
				sessionStorage.setItem('token', '');
				sessionStorage.setItem('userInfo', null);
				this.$store.commit('setUserinfo', null);
				this.$message.success('用户退出成功');
				this.$router.push('/');
			},


			printFile(obj) {



				let LODOP = getLodop();
				console.log('打印参数', obj);
				//return false;

				//黑白
				if (obj.paper_type == 1) {
					obj.print_file = obj.print_hb_file
				}






				//this.loading = true;
				let self = this;

				LODOP.PRINT_INIT("测试PDF打印功能");


				if (localStorage.getItem('model') == '1') {
					if (obj.print_type == 1) { //a3纸
						this.$message.error('对不起，当前设备不支持A3纸打印');
						return false;
					}

					LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
					LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
				} else {
					LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);

					if (obj.print_type == 1) { //a3纸
						LODOP.SET_PRINT_PAGESIZE(2, 0, 0, 'A3');
						//console.log('打印A3纸张')

					} else { //a4纸张
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
						//console.log('打印A4纸张')
					}


				}



				LODOP.ADD_PRINT_PDF(0, 0, "100%", "100%", this.demoDownloadPDF(this.$domain + obj.print_file));
				if (obj.print_ctype == '2') { //双面打印
					console.log('设置双面打印222')
					//LODOP.SET_PRINT_MODE("DOUBLE_SIDED_PRINT",true);

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
				} else {
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1);
				}

				LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
				//LODOP.SET_PRINT_STYLE('FontColor','#000')
				LODOP.SET_PRINT_STYLEA(0, "FontColor", "#808080");

				LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);
				//  LODOP.SET_PRINT_MODE("PRINT_DUPLEX",2);
				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("Value:" + Value); //job代码
					self.jobCode = Value;
					var timer = setInterval(function() {
						LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 2);
						console.log("每次轮询的状态：" + self.printStatus);
						if (self.printStatus != 0) { //打印成功
							clearInterval(timer);
							self.printStatus = 1;
							self.loading = false;
							self.open();

							let data = {
								'task_number': obj.task_number,
								'device_number': localStorage.getItem('device')
							};
							self.accomplish(data);

							return;
						}
						self.getStatusValue(Value);
					}, 1000);
				};
				//LODOP.PRINT();
				LODOP.PRINT();

				return;



			},
			async accomplish(data) {

				let res = await this.$api.accomplish(data);
				console.log(111);
				//this.loading = false;
				//this.TaskList();
			},



			demoDownloadPDF(url) {
				if (!(/^https?:/i.test(url))) return;
				if (window.XMLHttpRequest) var xhr = new XMLHttpRequest();
				else var xhr = new ActiveXObject("MSXML2.XMLHTTP");
				xhr.open('GET', url, false); //同步方式
				if (xhr.overrideMimeType)
					try {
						xhr.responseType = 'arraybuffer';
						var arrybuffer = true;
					} catch (err) {
						xhr.overrideMimeType('text/plain; charset=x-user-defined');
					}
				xhr.send(null);
				var data = xhr.response || xhr.responseBody;
				if (typeof Uint8Array !== 'undefined') {
					if (arrybuffer) var dataArray = new Uint8Array(data);
					else {
						var dataArray = new Uint8Array(data.length);
						for (var i = 0; i < dataArray.length; i++) {
							dataArray[i] = data.charCodeAt(i);
						}
					}
				} else
					var dataArray = VBS_BinaryToArray(data).toArray(); //兼容IE低版本
				return this.demoGetBASE64(dataArray);
			},

			demoGetBASE64(dataArray) {
				var digits = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
				var strData = "";
				for (var i = 0, ii = dataArray.length; i < ii; i += 3) {
					if (isNaN(dataArray[i])) break;
					var b1 = dataArray[i] & 0xFF,
						b2 = dataArray[i + 1] & 0xFF,
						b3 = dataArray[i + 2] & 0xFF;
					var d1 = b1 >> 2,
						d2 = ((b1 & 3) << 4) | (b2 >> 4);
					var d3 = i + 1 < ii ? ((b2 & 0xF) << 2) | (b3 >> 6) : 64;
					var d4 = i + 2 < ii ? (b3 & 0x3F) : 64;
					strData += digits.substring(d1, d1 + 1) + digits.substring(d2, d2 + 1) + digits.substring(d3, d3 + 1) +
						digits.substring(d4, d4 + 1);
				}
				return strData;
			},

			getStatusValue(job) { //根据job代码，获取是否打印成功

				var self = this;
				LODOP = getLodop();
				LODOP.On_Return = function(TaskID, Value) {
					console.log("TaskID:" + TaskID);
					console.log("打印成功状态:" + Value); //打印成功状态
					self.printStatus = Value;
				};
				LODOP.GET_VALUE("PRINT_STATUS_EXIST", job);
			},



			//打印照片
			print(obj) {


				console.log('照片', obj);
				//	return false;



				let self = this;
				this.loading = true;

				if (obj.task_type == 5) { //身份证直接用图片打印
					let LODOP = getLodop();
					console.log('打印身份证');

					if (obj.paper_type == 1) {
						obj.print_file = obj.print_hb_file
					}

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
					}


					//	return false;
					LODOP.ADD_PRINT_IMAGE("10%", "25%", "100%", "100%", `<div style="width:42cm; height:59.4cm; ">
				    <img style="width:500px; height:630px" src=${obj.print_file}>
				  </div>`);

					LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);



					LODOP.On_Return = function(TaskID, Value) {
						console.log("TaskID:" + TaskID);
						console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus != 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.loading = false;
								self.open();

								let data = {
									'task_number': obj.task_number,
									'device_number': localStorage.getItem('device')
								};
								self.accomplish(data);

								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};
					LODOP.PRINT();
					//	LODOP.PRINT();



					return false;
				}



				//图片a4纸打印
				if (obj.task_type == 2 && obj.print_type == 2) {
					let LODOP = getLodop();
					console.log('图片a4纸打印');

					if (obj.paper_type == 1) {
						obj.print_file = obj.print_hb_file
					}

					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1); //单面打印

					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(this.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4 210 x 297 毫米');
					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['iR-ADV C5235']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, 'A4');
					}



					//	return false;
					LODOP.ADD_PRINT_IMAGE("0", "0", "100%", "100%",
						` <img style="width:21cm; height:29.7cm" src=${this.$domain+obj.print_file}>`);


					LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);



					LODOP.On_Return = function(TaskID, Value) {
						console.log("TaskID:" + TaskID);
						console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus != 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.loading = false;
								self.open();

								let data = {
									'task_number': obj.task_number,
									'device_number': localStorage.getItem('device')
								};
								self.accomplish(data);

								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};
					LODOP.PRINT();
					//LODOP.PRINT();



					return false;
				}




				//console.log('准备打印照片', obj.print_type)
				console.log('照片参数111', obj)
				let boxname = "";
				switch (obj.print_type + "") {
					case '3': //一寸
						boxname = 'box1'
						break;
					case '4': //两寸
						boxname = 'box2'
						break;
					case '5': //大一寸
						boxname = 'box3'
						break;
					case '6': //大两寸
						boxname = 'box4'
						break;
					case '7': //6寸
						boxname = 'box5'
						break;
				}



				this.$nextTick(() => {

					//console.log('self.LODOP',self.LODOP)

					console.log(boxname, this.$refs[boxname]);

					var str = this.$refs[boxname].innerHTML;
					console.log('str', str)
					//	return false;

					LODOP = getLodop();

					console.log('lodop111', LODOP)
					LODOP.SET_PRINT_MODE("PRINT_DUPLEX", 1);
					//寸照片
					//LODOP.SET_PRINT_PAGESIZE(1, '210mm', '297mm', 'A4');
					console.log('6寸照片')
					if (localStorage.getItem('model') == '1') {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['EPSON WF-C5290 Series']);
						LODOP.SET_PRINT_PAGESIZE(0, 0, 0, '102 x 152 毫米 (4 x 6 英寸)');


					} else {
						LODOP.SET_PRINTER_INDEXA(self.$store.state.devList['HiTi P525L']);
					}



					//LODOP.SET_PRINTER_INDEXA(0);
					LODOP.ADD_PRINT_IMAGE(0, 0, "100%", "100%", str);
					LODOP.SET_PRINT_STYLEA(0, "Stretch", 1);
					LODOP.SET_PRINT_COPIES(obj.print_number); //打印份数
					console.log('打印份数', obj.print_number)


					LODOP.SET_PRINT_MODE("CATCH_PRINT_STATUS", true);

					LODOP.On_Return = function(TaskID, Value) {
						console.log("TaskID:" + TaskID);
						console.log("Value:" + Value); //job代码
						self.jobCode = Value;
						var timer = setInterval(function() {
							console.log("每次轮询的状态：" + self.printStatus);
							if (self.printStatus == 0) { //打印成功
								clearInterval(timer);
								self.printStatus = 1;
								self.loading = false;
								self.open();

								let data = {
									'task_number': obj.task_number,
									'device_number': localStorage.getItem('device')
								};
								self.accomplish(data);


								return;
							}
							self.getStatusValue(Value);
						}, 1000);
					};
					LODOP.PRINT();

					//	LODOP.PRINT();
					console.log('预览')

					return;

				})
			},








		}


	}
</script>

<style scoped lang="scss">
	.box {
		width: 800px;
		padding-top: 100px;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;

		.item {
			width: 300px;
			height: 350px;
			/* background: yellow; */
			margin: 0 auto;

			&:nth-child(1) {
				background-color: rgba(68, 227, 24, 100);
				;
			}

			&:nth-child(2) {
				background-color: #36CFC9;
			}

			img {
				width: 200px;
				height: 200px;
				display: block;
				margin: 25px auto;
			}

			p {
				font-size: 20px;
				font-weight: bold;
				color: #fff;
				text-align: center;
			}

			::v-deep .el-upload {
				display: block;

				img {
					height: 160px;
				}
			}

			::v-deep .el-upload-list {
				display: none !important;
			}

			::v-deep .el-upload-dragger {
				background-color: #36CFC9;
				border: none;
				width: 200px;
				height: 200px;

				margin: 25px auto;
			}

		}




	}

	.top {
		margin-top: -15px;
		height: 30px;
		color: #fff;
	}

	.left {
		float: left;
	}

	.right {
		float: right;
	}

	.photobox {
		display: none;
	}
</style>

<style>
	.el-loading-spinner .el-loading-text {
		color: #fff !important;
		font-size: 30px !important;
	}

	.el-loading-spinner .el-icon-loading {
		color: #fff !important;
		font-size: 100px !important;
	}
</style>
